let config = [];

$(document).ready(function() {
    if (CONFIGS !== '') {
        config = JSON.parse(CONFIGS);
        if (config.length > 0) {
            showStats();
            showAccounts();
        }
    }
});

function showAccounts() {
	document.getElementById("accountsList").innerHTML = "";

	let html = "<table id='scholarList' class='table table-dark table-striped leaderboard'>";
	    html += "<tr><th>#</th><th>Name</th><th>Rank</th><th>MMR</th><th>SLP/Day</th></tr>";

    if (config.length > 0) {
        document.getElementById("lastUpdatedAt").innerHTML = new Date(parseInt(config[0].last_updated_date)).toLocaleString();

        config.sort(function(a, b) {
            return parseFloat(b.elo) - parseFloat(a.elo);
        });

        for (let i = 0; i < config.length; i++) {
            let account = config[i];
            let row = "";

            row += "<tr>";
            row += "<td>" + (i + 1) + "</td>";
            row += "<td><a href='https://marketplace.axieinfinity.com/profile/" + account.ronin_address + "' target='_blank'>" + account.scholar_name + "</a></td>";
            row += "<td>" + account.rank + "</td>";
            row += "<td>" + account.elo + "</td>";
            row += "<td>" + account.daily_slp + "</td>";
            row += "</tr>";

            html = html.concat(row);
        }
    } else {
        document.getElementById("lastUpdatedAt").innerHTML = "(n/a)";
    }

	html = html + "</table>";
	document.getElementById("accountsList").innerHTML = html;
}

function showStats() {
	let highestSLPScholar = "-";
    let highestSLP = 0;
    let highestELOScholar = "-";
    let highestELO = 0;

	for (let i = 0; i < config.length; i++) {
        if (parseInt(config[i].daily_slp) > highestSLP) {
			highestSLPScholar = config[i].scholar_name;
			highestSLP = config[i].daily_slp;
		}

        if (parseInt(config[i].elo) > highestELO) {
			highestELOScholar = config[i].scholar_name;
			highestELO = config[i].elo;
		}
	}

	document.getElementById("highestELOScholar").innerHTML = highestELOScholar;
    document.getElementById("highestSLPScholar").innerHTML = highestSLPScholar;

	if (config.length > 0) {
		document.getElementById("lastUpdatedAt").innerHTML = new Date(parseInt(config[0].last_updated_date)).toLocaleString();
	} else {
		document.getElementById("lastUpdatedAt").innerHTML = "(n/a)";
	}
}
